const SWITCHER_CLASS_ACTIVE = 'wc-switcher--active';
const SWITCHER_ITEMS_CLASS_ACTIVE = 'wc-switcher__items--active';
const RESTYLED_ITEMS_CLASS_ACTIVE = 'wc-switcher-restyled__items--active';

$(document).ready(function () {
  $('.wc-switcher, .wc-switcher-restyled').on('click', function (ev) {
    ev.preventDefault();

    const $this = $(this);

    const isRestyled = $this.hasClass('wc-switcher-restyled');
    const $link = $this.find('.wc-switcher__link');
    const $target = $('#' + $link.data('switcher-toggle'));
    const isActive = $this.hasClass(SWITCHER_CLASS_ACTIVE);

    $this.toggleClass(SWITCHER_CLASS_ACTIVE);
    $target.toggleClass(isRestyled ? RESTYLED_ITEMS_CLASS_ACTIVE : SWITCHER_ITEMS_CLASS_ACTIVE);

    if (isActive) {
      $this.removeClass(SWITCHER_CLASS_ACTIVE);
      $target.removeClass(isRestyled ? RESTYLED_ITEMS_CLASS_ACTIVE : SWITCHER_ITEMS_CLASS_ACTIVE);
    } else {
      $('.' + SWITCHER_CLASS_ACTIVE).not($this).removeClass(SWITCHER_CLASS_ACTIVE);
      $('.' + (isRestyled ? RESTYLED_ITEMS_CLASS_ACTIVE : SWITCHER_ITEMS_CLASS_ACTIVE))
        .not($target).removeClass(isRestyled ? RESTYLED_ITEMS_CLASS_ACTIVE : SWITCHER_ITEMS_CLASS_ACTIVE);
    }
  });
});
